import {
  addDividersBetweenElements,
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxDivider,
  AgxHeader,
  AgxMultiOptionButton,
  AgxRow,
  AgxSlideUpModal,
  Images,
  StakeholderType,
  DocumentTypes,
  DocumentTypesMap,
  DocumentTypesOrderMap,
  FormType,
  CampaignDetailModel,
  EnvelopeSubmissionRecipient,
  EnvelopeSubmissionStatus,
  EnvelopeSubmissionType,
} from '@urbanx/agx-ui-components';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import FormDetailsHeader from '../../Common/FormDetailsHeader';
import placeHolderImage from 'assets/images/placeholder-property.png';
import { Signatory } from '../../../CampaignsList/components/SentForSigningPanel/Signatory';
import { campaignsApi, completeCampaignStep } from 'Api/Campaigns/campaignsApi';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { setAndShowErrorToast } from 'store/config';
import { useGetFileLink } from 'components/ui-components/File/fileApi';
import {
  selectContract,
  removeFormSubmission,
} from 'features/campaigns/campaignsReducer';
import { prepareSignatories, getAllBuyerNames } from 'utils/vendorBuyerUtil';
import { formatListOfStrings } from 'utils/formatString';
import { formatCurrency } from 'utils/formatNumber';
import { useSelectedContract } from 'hooks/useSelectedContract';
import SigningStatus from '../../Common/SigningStatus';
import { useFormLoader } from 'hooks/useFormLoader';
import { Tabs } from 'types/Tabs';
import SendToVendorsConfirmation from './SendToVendorsConfirmation';
import { MultiOptionButtonOption } from '@urbanx/agx-ui-components/dist/cjs/types/components/actions/MultiOptionButton/MultiOptionButton';
import './ContractRequestSigningPanel.scss';
import { openFileInNewTab } from 'utils/openFileInNewTab';

interface ContractRequestSigningPanelProps {
  campaign: CampaignDetailModel;
  currentTab: Tabs;
  mobile?: boolean;
}

const ContractRequestSigningPanel = ({
  campaign,
  currentTab,
  mobile = false,
}: ContractRequestSigningPanelProps) => {
  const [, getAuthToken] = useAzureAuth();
  const isDesktop = ScreenSize() === Breakpoints.Desktop;
  const { loadForm, loadFormWithConfig } = useFormLoader();
  const selectedContract = useSelectedContract();
  const {
    address,
    propertyImageUrls: { medium: imageUrl = null } = {},
    campaignId,
  } = campaign;
  const { buyers, purchasePrice, lastUpdate, envelopeSubmission } =
    selectedContract ?? {};
  const {
    formDocumentSubmissionId: envelopeId,
    status: envelopeSubmissionStatus,
    type: envelopeSubmissionType,
    recipients,
  } = envelopeSubmission ?? {};

  const contractSent =
    envelopeSubmissionStatus &&
    [
      EnvelopeSubmissionStatus.Signed,
      EnvelopeSubmissionStatus.Complete,
      EnvelopeSubmissionStatus.Sent,
    ].includes(envelopeSubmissionStatus);

  const headerDetail = `${contractSent ? 'Sent' : 'Last updated'} ${moment(
    lastUpdate
  ).format('DD/MM/YY')}`;
  const availableForms = useAppSelector(state => state.forms.availableForms);
  const [disableInputs, setDisableInputs] = useState(false);
  const [disableSendToVendors, setDisableSendToVendors] = useState(false);
  const [showVoidEditConfirmationModal, setShowVoidEditConfirmationModal] =
    useState(false);
  const [
    showSendVendorsConfirmationModal,
    setShowSendVendorsConfirmationModal,
  ] = useState(false);
  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(address);
  const isMobile = ScreenSize() === Breakpoints.Mobile;
  const dispatch = useAppDispatch();
  const approver = recipients?.find(
    (r: any) => r.stakeholderType === StakeholderType.Approver
  );
  const vendors = prepareSignatories(campaign.vendors, StakeholderType.Vendor);
  const allBuyers = prepareSignatories(buyers, StakeholderType.Buyer);
  const allVendorSolicitors = prepareSignatories(
    buyers,
    StakeholderType.VendorSolicitor
  );
  const allBuyerSolicitors = prepareSignatories(
    buyers,
    StakeholderType.BuyerSolicitor
  );
  const allSignatories = [
    ...allBuyers,
    ...vendors,
    ...allVendorSolicitors,
    ...allBuyerSolicitors,
  ];

  const declinedStatus = recipients?.find(
    recipient => recipient.status === EnvelopeSubmissionStatus.Declined
  );

  const bouncedStatus = recipients?.find(
    recipient => recipient.status === EnvelopeSubmissionStatus.Bounced
  );

  const allSigned = (
    recipients: EnvelopeSubmissionRecipient[],
    stakeHolderType: StakeholderType
  ) => {
    const stakeHolders = recipients.filter(
      x => x.stakeholderType === stakeHolderType
    );
    const validStatuses = [
      EnvelopeSubmissionStatus.Signed,
      EnvelopeSubmissionStatus.Complete,
    ];
    return (
      stakeHolders.length > 0 &&
      stakeHolders.every(x => validStatuses.includes(x.status))
    );
  };

  const AllBuyersSigned = allSigned(recipients ?? [], StakeholderType.Buyer);
  const AllVendorsSigned = allSigned(recipients ?? [], StakeholderType.Vendor);
  const getFileLink = useGetFileLink();

  const noAttachments = useMemo(() => {
    const documentTypes = selectedContract?.documents.map(
      doc => doc.documentType
    );
    const uniqueTypes = new Set(documentTypes);
    // Check if the unique types are one 'AgencyAgreement' and one 'MergedAgencyAgreement'
    return (
      uniqueTypes.size === 2 &&
      uniqueTypes.has(DocumentTypes.MergedSalesContract)
    );
  }, [selectedContract?.documents]);

  const fileDownloadOptions = useMemo(() => {
    if (selectedContract?.documents.length === 0) return [];
    const options: MultiOptionButtonOption[] = [];
    const miscDocumentTypes = [
      DocumentTypes.Miscellaneous,
      DocumentTypes.AdditionalAnnexure,
    ];

    const orderedDocuments = [...(selectedContract?.documents ?? [])].sort(
      (a, b) =>
        DocumentTypesOrderMap[a.documentType] -
        DocumentTypesOrderMap[b.documentType]
    );

    const nonMiscDocuments = orderedDocuments.filter(
      (d, ind, arr) =>
        !miscDocumentTypes.includes(d.documentType) &&
        (!ind || d.documentType !== arr[ind - 1]?.documentType)
    );
    const miscDocuments = orderedDocuments.filter(d =>
      miscDocumentTypes.includes(d.documentType)
    );

    nonMiscDocuments.forEach(d => {
      options.push({
        text: DocumentTypesMap[d.documentType],
        onClick: async () => {
          const fileLink = await getFileLink(d.containerFilePath);
          if (fileLink != null) {
            openFileInNewTab(isDesktop, fileLink);
          }
        },
      });
    });

    miscDocuments.forEach(d => {
      options.push({
        text: d.fileName,
        onClick: async () => {
          const fileLink = await getFileLink(d.containerFilePath);
          if (fileLink != null) {
            openFileInNewTab(isDesktop, fileLink);
          }
        },
      });
    });

    return options;
  }, [selectedContract?.documents, getFileLink]);

  const beginSubmission = async () => {
    const contractSubmissionForm = availableForms.find(
      form =>
        form.type === FormType.ContractSubmission &&
        isEqual(form.state, campaign.state)
    );

    if (contractSubmissionForm === null) return;

    try {
      setDisableInputs(true);
      const authToken = await getAuthToken();

      if (!authToken || !selectedContract) return;

      const newForm = await completeCampaignStep(
        authToken,
        campaignId,
        false,
        selectedContract.contractId
      );

      if (!newForm) {
        dispatch(setAndShowErrorToast('Failed to start Contract submission'));
        setDisableInputs(false);
        return;
      }

      await loadFormWithConfig(newForm);
    } catch (err) {
      dispatch(setAndShowErrorToast('Failed to start Contract submission'));
      setDisableInputs(false);
    }
  };

  const sendToVendors = async () => {
    setDisableInputs(true);
    setDisableSendToVendors(true);
    try {
      const authToken = await getAuthToken();

      if (!authToken || !selectedContract) return;

      await campaignsApi(authToken).post('ApproveEnvelope', {
        campaignId: campaignId,
        formId: selectedContract.contractId,
        envelopeId: envelopeId,
      });
      setShowSendVendorsConfirmationModal(false);
      setDisableInputs(false);
    } catch (err) {
      dispatch(setAndShowErrorToast('Failed to send contract to vendors'));
      setDisableInputs(false);
      setDisableSendToVendors(false);
    }
  };

  const allRecipients =
    recipients?.filter(
      signatory =>
        signatory.stakeholderType !== StakeholderType.Approver &&
        (!approver || signatory.stakeholderType !== StakeholderType.Vendor)
    ) ?? [];

  const allRecipientsInfo = allRecipients.map(recipient => {
    const allSignatoriesInfo = allSignatories.find(
      signatory => recipient.recipientId === signatory.id
    );

    if (allSignatoriesInfo) {
      return { ...recipient, name: allSignatoriesInfo.name };
    }

    return recipient;
  });

  const renderSignatories = () => {
    const signatoryComponents = allRecipientsInfo.map((signatory, i) => {
      if (
        signatory &&
        envelopeId &&
        ((envelopeSubmissionType === EnvelopeSubmissionType.DocuSign &&
          (!approver ||
            signatory.stakeholderType !== StakeholderType.Vendor)) ||
          envelopeSubmissionType === EnvelopeSubmissionType.Email)
      ) {
        return (
          <Signatory
            key={`signatory-${i}`}
            signatory={signatory}
            envelopeSubmissionType={envelopeSubmissionType}
            envelopeId={envelopeId}
            declinedStatus={!!(declinedStatus || bouncedStatus)}
          />
        );
      }

      return <></>;
    });

    return [
      <AgxDivider key="initialDivider" />,
      ...addDividersBetweenElements(signatoryComponents, false, false),
    ];
  };

  const onEditContract = async () => {
    if (!selectedContract) return;

    if (envelopeSubmissionType === EnvelopeSubmissionType.Email) {
      await loadForm(campaignId, selectedContract.contractId);
    } else if (envelopeSubmissionType === EnvelopeSubmissionType.DocuSign) {
      setShowVoidEditConfirmationModal(true);
    }
  };

  const onVoidAndEdit = async () => {
    if (!selectedContract) return;

    setDisableInputs(true);

    try {
      const authToken = await getAuthToken();

      if (!authToken || !envelopeId) return;

      await removeFormSubmission(
        authToken,
        campaignId,
        envelopeId,
        selectedContract.contractId
      );

      await loadForm(campaignId, selectedContract.contractId);
    } catch (err) {
      console.error(err);
      dispatch(setAndShowErrorToast('Failed to void envelope'));
    }

    setDisableInputs(false);
  };

  const SubmitContractButton = () => {
    return (
      <AgxButton
        primary
        wide={mobile}
        large
        text="Start Submission"
        disabled={
          disableInputs ||
          (envelopeSubmissionType === EnvelopeSubmissionType.DocuSign &&
            !(AllBuyersSigned && AllVendorsSigned)) ||
          (envelopeSubmissionType === EnvelopeSubmissionType.Email &&
            envelopeSubmissionStatus !== EnvelopeSubmissionStatus.Sent)
        }
        rightIcon={<Images.FolderAddOutline />}
        onClick={beginSubmission}
        shrinkOnLargerDevices
      />
    );
  };

  const DocuSignVendorButton = () => {
    return (
      <div className={isMobile ? 'campaignModalButtons' : ''}>
        <AgxButton
          primary
          wide={mobile}
          large
          text="Send to Vendors"
          rightIcon={<Images.EmailOutline />}
          onClick={() => setShowSendVendorsConfirmationModal(true)}
          shrinkOnLargerDevices
          disabled={
            (!AllBuyersSigned && approver !== undefined) ||
            disableSendToVendors ||
            disableInputs
          }
        />
      </div>
    );
  };
  const ViewContractButton = () => {
    if (fileDownloadOptions.length === 0) return <></>;

    return fileDownloadOptions.length === 1 || noAttachments ? (
      <AgxButton
        id="downloadContract"
        text="Download"
        hollow
        large={!isMobile}
        small={isMobile}
        rightIcon={<Images.Download />}
        wide={isMobile}
        onClick={fileDownloadOptions[0].onClick}
      />
    ) : (
      <AgxMultiOptionButton
        id="downloadContracts"
        text="Download"
        hollow
        large={!isMobile}
        small={isMobile}
        rightIcon={<Images.Download />}
        wide={isMobile}
        options={fileDownloadOptions}
      />
    );
  };

  const EditContractButton = () => {
    return (
      <AgxButton
        hollow
        wide={mobile}
        large={!isMobile}
        small={isMobile}
        text="Edit"
        rightIcon={<Images.EditOutline />}
        onClick={onEditContract}
        shrinkOnLargerDevices
        disabled={disableInputs || (AllBuyersSigned && AllVendorsSigned)}
      />
    );
  };

  const voidEditConfirmationDialogBody = (
    <AgxColumn veryLargeGap extraClasses={'confirmationModalStyle'}>
      <Images.AlertCircleOutline />
      <AgxHeader size={3}>
        Editing contract will void current DocuSign envelope
      </AgxHeader>
      <AgxBodyText medium>
        Current recipients will be notified, you'll be able to generate a new
        contract and send a new DocuSign envelope.
      </AgxBodyText>
      <AgxBodyText medium>Continue to edit?</AgxBodyText>
      {isMobile && <AgxDivider />}
      <div className={'confirmationButtonContainerStyle'}>
        <AgxButton
          id="startsubmission"
          large
          primary
          text="Void and Edit"
          rightIcon={<Images.EditOutline />}
          onClick={onVoidAndEdit}
          disabled={disableInputs}
        />
        <AgxButton
          id="cancel"
          hollow
          large
          text="No, go back"
          rightIcon={<Images.CloseOutline />}
          onClick={() => setShowVoidEditConfirmationModal(false)}
          disabled={disableInputs}
        />
      </div>
    </AgxColumn>
  );

  const confirmSendToVendorsDialogBody = (
    <AgxColumn veryLargeGap extraClasses={'confirmationModalStyle'}>
      <AgxHeader size={3}>Vendor Signatories</AgxHeader>
      <SendToVendorsConfirmation
        vendors={vendors}
        onBack={() => setShowSendVendorsConfirmationModal(false)}
        onSend={sendToVendors}
        disabled={disableInputs}
      />
    </AgxColumn>
  );

  return (
    <>
      <FormDetailsHeader
        formName="Contract"
        detail={headerDetail}
        currentTab={currentTab}
        onBack={() => dispatch(selectContract(null))}
      />

      <AgxColumn veryLargeGap fill centered>
        <SigningStatus
          status={
            bouncedStatus
              ? EnvelopeSubmissionStatus.Bounced
              : envelopeSubmissionStatus
          }
          type={envelopeSubmissionType}
        />
        <AgxHeader size={2} centered>
          {addressLineOne},
          <br />
          {addressLineTwo}
        </AgxHeader>
        <AgxBodyText medium centered>
          {formatListOfStrings(getAllBuyerNames(allBuyers))} •{' '}
          {formatCurrency(purchasePrice ?? null, 0)}
        </AgxBodyText>
        <div className="propertyImageContainer">
          <img
            className="propertyImage"
            src={imageUrl ?? placeHolderImage}
            alt="Property"
          />
        </div>
        <AgxRow largeGap>
          <ViewContractButton />
          {envelopeSubmissionType !== EnvelopeSubmissionType.Email && (
            <EditContractButton />
          )}
          {!isMobile &&
            !approver &&
            envelopeSubmissionType !== EnvelopeSubmissionType.Email && (
              <SubmitContractButton />
            )}
          {!isMobile && approver && <DocuSignVendorButton />}
        </AgxRow>

        <AgxColumn fill centered extraClasses={'signatoriesContainer'}>
          {renderSignatories()}
        </AgxColumn>
      </AgxColumn>

      {isMobile &&
        !approver &&
        envelopeSubmissionType !== EnvelopeSubmissionType.Email && (
          <div className="campaignModalButtons">
            <SubmitContractButton />
          </div>
        )}
      {isMobile && approver && <DocuSignVendorButton />}

      {showVoidEditConfirmationModal && (
        <AgxSlideUpModal
          closeButton
          content={voidEditConfirmationDialogBody}
          desktop={!isMobile}
          onClose={() => setShowVoidEditConfirmationModal(false)}
        />
      )}

      {showSendVendorsConfirmationModal && (
        <AgxSlideUpModal
          closeButton
          content={confirmSendToVendorsDialogBody}
          desktop={!isMobile}
          onClose={() => setShowSendVendorsConfirmationModal(false)}
        />
      )}
    </>
  );
};

export default ContractRequestSigningPanel;
