import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AustralianStateNumericMap,
  FormDetails,
} from '@urbanx/agx-ui-components';
import { useAzureAuth } from '../../hooks/useAzureAuth';
import { campaignsApi } from 'Api/Campaigns/campaignsApi';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { LoadingState } from '../../utils/loadingState';
import { setAndShowErrorToast } from '../../store/config';

export interface FormsState {
  loadingState: LoadingState;
  availableForms: FormDetails[];
}

const initialState = {
  loadingState: LoadingState.NotLoaded,
  availableForms: [],
} satisfies FormsState as FormsState;

const slice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    startLoadingForms: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Loading,
      };
    },
    formsLoaded: (state, action: PayloadAction<FormDetails[]>) => {
      const forms = action.payload;

      return {
        ...state,
        availableForms: [...forms],
        loadingState: LoadingState.Loaded,
      };
    },
    errorLoadingForms: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Failed,
      };
    },
  },
});

export default slice.reducer;

const { startLoadingForms, formsLoaded, errorLoadingForms } = slice.actions;

export const useFetchAvailableForms = () => {
  const dispatch = useAppDispatch();
  const [, getAuthToken] = useAzureAuth();

  const fetchAvailableForms = async (states: AustralianStateNumericMap[]) => {
    if (!getAuthToken) return;

    try {
      const authToken = await getAuthToken();

      if (!authToken) return;

      dispatch(startLoadingForms());
      const { data: forms } = await campaignsApi(authToken).post<FormDetails[]>(
        'FormsByLocations',
        states
      );

      dispatch(formsLoaded(forms));
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(errorLoadingForms());
    }
  };

  return fetchAvailableForms;
};
